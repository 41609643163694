<template>
  <div>
    <hr />
    <div class="fw-bold">Réservations</div>

    <div class="row align-items-stratch">
      <div class="col-xl-3 col-6 my-3">
        <label for=""> Date </label>
        <div>
          {{ reservation.date }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Heure </label>
        <div>
          {{ reservation.startTime }}
        </div>
      </div>
    </div>

    
   
    <div class="fw-bold">Informations client</div>
    <div class="row">
      <div class="col-xl-3 col-6 my-3 my-3">
        <label for=""> Prénom : </label>
        <div v-if="reservation.field">
          {{ reservation.field.firstName }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Nom : </label>
        <div v-if="reservation.field">
          {{ reservation.field.lastName }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for="">Téléphone : </label>
        <div v-if="reservation.field">
          {{ reservation.field.phone }}
        </div>
      </div>
</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("reservation", {
      reservation: "getReservation",
    }),
   
  },
  methods: {
    
  },
  async beforeMount() {
    await this.$store.dispatch("reservation/show",this.$route.params.reference);

  },
};
</script>
